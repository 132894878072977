import { Content } from '@prismicio/client';
import {
  JSXMapSerializer,
  SliceComponentProps,
  PrismicRichText,
} from '@prismicio/react';
import Container from '@/components/Unknown/Container';
// For carousel
import SwiperCore from 'swiper';
import {
  Pagination,
  Keyboard,
  Mousewheel,
  Autoplay,
  Grid,
} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styles from './customSwipperStyles.module.scss';
import { PrismicNextImage } from '@prismicio/next';

SwiperCore.use([Pagination, Keyboard, Mousewheel, Autoplay, Grid]);

/**
 * Props for `ListedFeatures`.
 */
export type ListedFeaturesProps =
  SliceComponentProps<Content.ListedFeaturesSlice>;

/**
 * Component for "ListedFeatures" Slices.
 */

const component: JSXMapSerializer = {
  heading2: ({ children }) => (
    <h2 className="mx-auto text-pretty text-center text-arc-4xl font-bold ~md:~max-w-[42.5rem]/[54.375rem] sm:~lg:~text-arc-7xl/arc-9xl">
      {children}
    </h2>
  ),
  heading3: ({ children }) => (
    <h3 className="text-balance text-center font-bold ~lg:~text-arc-xl/arc-2xl">
      {children}
    </h3>
  ),
  paragraph: ({ children }) => (
    <p className="mx-auto max-w-prose text-pretty text-center font-normal ~lg:~text-arc-base/arc-2xl">
      {children}
    </p>
  ),
};

const ListedFeatures = ({ slice }: ListedFeaturesProps): JSX.Element => {
  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className="bg-paper text-black ~/md:~py-16/20 md:~md/lg:~py-20/[4.5rem] lg:~lg:~py-[4.5rem]/[5.625rem]"
    >
      <Container>
        <div className="px-0 o-6 md:px-16">
          <PrismicRichText
            field={slice.primary.heading}
            components={component}
          />
          <PrismicRichText
            field={slice.primary.sub_heading}
            components={component}
          />
        </div>
        <Swiper
          className={styles.swiper}
          loop
          pagination={{
            clickable: true,
          }}
          wrapperClass={`swiper-wrapper ${styles['swiper-wrapper']}`}
          spaceBetween={16}
          wrapperTag="ul"
          keyboard={{
            enabled: true,
          }}
          breakpoints={{
            '640': {
              enabled: false,
              slidesPerView: 3,
              grid: {
                rows: 2,
                fill: 'row',
              },
            },
            '320': {
              slidesPerView: 1.5,
              centeredSlides: true,
              spaceBetween: 8,
            },
          }}
        >
          {slice.primary.features.map((feature, index) => (
            <SwiperSlide
              key={index}
              className={styles['swiper-slide']}
              tag="li"
            >
              <PrismicNextImage
                field={feature.icon}
                className="aspect-square max-h-[100px] max-w-[100px] object-contain"
                fallbackAlt=""
              />
              <div className="flex flex-col items-center ~gap-4/6">
                <PrismicRichText
                  field={feature.heading}
                  components={component}
                />
                <PrismicRichText
                  field={feature.description}
                  components={{
                    paragraph: ({ children }) => (
                      <p className="mx-auto max-w-prose text-pretty text-center text-base font-normal sm:~lg:~text-base/lg">
                        {children}
                      </p>
                    ),
                  }}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </section>
  );
};

export default ListedFeatures;
